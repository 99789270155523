import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Row, Spinner } from "react-bootstrap";
import GET_ARTICLES from "../../graphql/queries/requests/getArticles"; 
import "./index.scss";

const ArticlesSlider = ({ articles }) => {

  const {
    loading: loadingArticles,
    error: errorArticles,
    data: dataArticles,
  } = useQuery(GET_ARTICLES);

  const truncateContent = (content, maxLength) => {
    if (!content) return "";
    return content.length > maxLength ? content.substring(0, maxLength) + "..." : content;
  };

  const SliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    draggable: true,
  };

  const handleMouseDown = (e) => {
    e.preventDefault(); 
  };

  if (loadingArticles) {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <Spinner
          as="div"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="align-middle my-auto"
        />
        <span>Loading...</span>
      </div>
    );
  }

  if (errorArticles)
    return (
      <div className="text-center text-danger mx-auto">An error occurred</div>
    );

  return (
    <Row style={{ marginLeft: 0, marginRight: 0, marginTop: 40, marginBottom: 40, justifyContent: "center" }}>
      <div onMouseDown={handleMouseDown} style={{ width: "100%", maxWidth: "800px", textAlign: "center" }}>
        <Slider {...SliderSettings}>
          {articles
            ?.slice(-3) // Get the last 3 articles
            .reverse()  // Reverse the array so that the newest article appears first
            .map((article) => (
              <div key={article.id} className="article-container">
                <Link to={`/blog-posts/single-view/${article.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <div className="article" style={{ 
                    background: "#f9f9f9", 
                    borderRadius: "24px", 
                    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                    padding: "20px", 
                    margin: "0 10px", 
                    textAlign: "center", 
                  }}>
                    <h2 style={{ fontSize: "1.5rem", marginBottom: "10px" }}>{article.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: truncateContent(article.body?.processed || article.body?.value, 300) }} />
                    <small style={{ display: "block", marginTop: "10px" }}>Created: {new Date(article.created).toLocaleDateString()}</small>
                  </div>
                </Link>
              </div>
            ))}
        </Slider>
      </div>
    </Row>
  );
};

export default ArticlesSlider;
