import { gql } from "@apollo/client";

const GET_ARTICLES = gql`
  query articles {
    articles {
      type
      id
      self_link
      title
      created
      changed
      langcode
      body {
        value
        format
        processed
        summary
      }
      drupal_internal__nid
      drupal_internal__vid
      revision_timestamp
      revision_log
      status
      promote
      sticky
      default_langcode
      revision_translation_affected
      path {
        alias
        pid
        langcode
      }
      comment {
        status
        cid
        last_comment_timestamp
        last_comment_name
        last_comment_uid
        comment_count
      }
      relationships {
        node_type {
          data {
            type
            id
            meta {
              drupal_internal__target_id
            }
          }
          links {
            related
            self
          }
        }
        revision_uid {
          data {
            type
            id
            meta {
              drupal_internal__target_id
            }
          }
          links {
            related
            self
          }
        }
        uid {
          data {
            type
            id
            meta {
              drupal_internal__target_id
            }
          }
          links {
            related
            self
          }
        }
        field_image {
          data {
            id
            type
            url
            alt
          }
          links {
            related
            self
          }
        }
        field_tags {
          data {
            id
            type
            url
            alt
          }
          links {
            related
            self
          }
        }
      }
    }
  }
`;

export default GET_ARTICLES;
