import { useQuery } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import CustomButton from "../../../../components/CustomButton";
import IconButton from "../../../../components/IconButton";
import Select from "../../../../components/Select";
import GET_HEI from "../../../../graphql/queries/IIAs/getHei";
import GET_OUNITS from "../../../../graphql/queries/IIAs/getOUnits";
import useUser from "../../../../hooks/useUser";
import "./index.scss";
import { useState } from "react";

const FirstStep = ({contacts, updateMultipleValues, ounit, ounitID}) => {
  const {user} = useUser();
  const [validated, setValidated] = useState(false);
  const {
    loading: loadingHei,
    error: errorHei,
    data: dataHei,
  } = useQuery(GET_HEI);

  const {
    loading: loadingOUnits,
    error: errorOUnits,
    data: dataOUnits,
  } = useQuery(GET_OUNITS, {variables: {heiID: user?.heiID}});


  const getFilterValueForSelectField = (el) => {
    if (el === "" || el === null || el === undefined) {
      return null;
    } else {
      return [{ label: el }];
    }
  };

  const handleInputArrayChange = (el) => {
    let temp = [];
    // if (temp.length === 0) {
    //     temp.push({value: el?.label});
    // } else {
    //     temp.pop();
    //     temp.push({value: el?.label});
    // }
    temp.push({value: el?.label, language: "en"});

    updateMultipleValues({ounit: temp, ounitID: el?.value});
};

  const handleAddContact = () => {
    const values = [...contacts];
    values.push({ contact_names: [], emails: [], phone_numbers: []});
    updateMultipleValues({contacts: values});
}

const handleRemoveContact = (index) => {
    let values = contacts.map((item) => ({
        ...item, 
        
      }))
      values.splice(index, 1);
    
      updateMultipleValues({contacts: values});
}

const handleInputContactNameChange = (index, event) => {
    let values = contacts.map((item) => ({
        ...item, 
      }));
      let validate = false;
      if (event.target.name === "name") {
        values[index].contact_names = [...values[index].contact_names];
        values[index].contact_names.pop();
        values[index].contact_names.push({value: event.target.value, language:"en"});
        } else if (event.target.name === "email") {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
              event.target.value ? validate = true : validate = false;
              event.preventDefault();
              event.stopPropagation();
              
            } 
            
            setValidated(true);
            values[index].emails = [...values[index].emails];
            values[index].emails.pop();
            values[index].emails.push(event.target.value);
            
            // values[index].emails[0] = event.target.value;
        }
        else {
            values[index].phone_numbers = [...values[index].phone_numbers];
            values[index].phone_numbers.pop();
            values[index].phone_numbers.push({e164: event.target.value});
        }

        updateMultipleValues({contacts: values, validateFirstEmail: validate});
}
  return (
    <>
    <Row style={{marginLeft: 25, marginRight: 25, background: "#EAECF3"}}>
                <Col style={{marginBottom: 12}} md={12} lg={12}>
                    
    <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                <div className="header">YOUR DETAILS</div>
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>Your Institution Data</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}} >
                            <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <Row style={{marginTop: 6}}>
                                    <Col lg={2}>Name: </Col>
                                    <Col lg={10} className='body'>{dataHei?.hei?.name}</Col>
                                </Row>
    
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Country: </Col>
                                    <Col lg={10} className='body'>{dataHei?.hei?.country}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>SCHAC Code: </Col>
                                    <Col lg={10} className='body'>{user.heiID}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Erasmus Code: </Col>
                                    <Col lg={10} className='body'>{user.erasmusCode}</Col>
                                </Row>

                                <Col lg={12} xs={12} style={{display: "flex"}}>
                                        
                                        
                                        <Form.Group className={"col-12"} controlId="formBasicName" style={{marginBottom: 5, marginTop: 30, marginRight: 10}}>
                                        <Form.Label>Organizational Unit</Form.Label>
                                        <Select
                                          options={[
                                            { value: null, label: "--null--" },
                                            ...(dataOUnits?.ounits || [])?.map((el) => ({
                                              value: el.ounit_id,
                                              label: el.ounit_name?.name}))]}
                                          placeholder="Select"
                                          onChange={(el) => {
                                            if (el?.value === null) {
                                              handleInputArrayChange(null);
                                            } else {
                                              handleInputArrayChange(el);
                                            }
                                          }}
                                          value={ounit?.length > 0 && ounit[0]?.value !== null ? getFilterValueForSelectField(ounit[0]?.value): null}

                                        />
                                        </Form.Group>
                                    
                                    
                                    </Col>
                                </div>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>Contact Persons</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' style={{background: "#F2F3F6"}} >
                            {contacts?.length > 0 && contacts?.map((elem, index) => <Row style={{marginBottom: 12, marginLeft: 5, marginRight: 1, background: "white", borderRadius: 10}}>
                                        <Col lg={11}>
                                        <div style={{marginBottom: 30, marginTop: 20}} className='label-style'>
                                        <Col lg={12} style={{display: "flex"}}>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter name.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="name"
                                                value={elem?.contact_names[0]?.value}
                                            />
                                            </Form.Group>
                                        </Form>
                                        </Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate validated={validated}
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="email"
                                                value={elem?.emails[0]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email address.
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                        </Form></Col>
                                        <Col lg={4}>
                                        <Form
                                            onSubmit={(e) => console.log("handle submit")}
                                            noValidate
                                        >
                                            <Form.Group className="col-10" controlId="formBasicName">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Phone.."
                                                onChange={(event) => handleInputContactNameChange(index, event)}
                                                required
                                                name="phone"
                                                value={elem?.phone_numbers[0]?.e164}
                                            />
                                            </Form.Group>
                                        </Form></Col>
                                        </Col>
                                        </div>
                                        </Col>
                                        <Col lg={1} style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                                            <IconButton
                                                variant="delete"
                                            
                                                title="Delete Contact"
                                                onClick={() => handleRemoveContact(index)}
                                            />
                                        </Col>
                                </Row>)}
                                <Row style={{marginLeft: 35, marginRight: 35}}>
                                        <Col style={{marginTop: 20, marginBottom: 16, display: "flex", justifyContent: "flex-end"}}>
                                            <CustomButton buttonType={"primary"} text={"add contact person"} icon={faPlus} hasIcon styles={{marginLeft: 20}} small handleClick={handleAddContact}/>
                                        </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  );
};

export default FirstStep;